.ant-skeleton {
  display: table;
  width: 100%;
  box-sizing: border-box;

  &.ant-skeleton-element {
    display: inline-block;
    width: auto;

    .ant-skeleton-image {
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 96px;
      height: 96px;
      line-height: 96px;

      .ant-skeleton-image-svg {
        width: 48px;
        height: 48px;
        line-height: 48px;
        max-width: 192px;
        max-height: 192px;
      }

      .ant-skeleton-image-path {
        fill: #bfbfbf;
      }
    }
    
    .ant-skeleton-input {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 160px;
      min-width: 160px;
      height: 32px;
      line-height: 32px;
    }
  }

  &.ant-skeleton-active {
    .ant-skeleton-image,
    .ant-skeleton-input {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
      background-size: 400% 100%;
      animation-name: ant-skeleton-loading;
      animation-duration: 1.4s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }

  &.ant-skeleton-block {
    width: 100%;
    
    .ant-skeleton-input { width: 100%; }
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
