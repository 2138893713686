.locl-label-error-provider {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  &_label-placement-left {
    display: grid;
    align-items: center;
    grid-column-gap: 16px;
    grid-template-areas:
      "label field"
      "empty error";

    .locl-label-error-provider__info,
    .locl-label-error-provider__label {
      grid-area: label;
    }

    &:not(:has(.locl-label-error-provider__error)) {
      row-gap: 0;
    }

    .locl-label-error-provider__error {
      grid-area: error;
    }
  }

  &_label-placement-right {
    display: grid;
    align-items: center;
    grid-column-gap: 8px;
    grid-template-areas:
      "field label"
      "empty error";

    .locl-label-error-provider__info,
    .locl-label-error-provider__label {
      grid-area: label;
    }

    &:not(:has(.locl-label-error-provider__error)) {
      row-gap: 0;
    }

    .locl-label-error-provider__error {
      grid-area: error;
    }
  }

  &__full-width {
    width: 100%;
  }

  &__info,
  &__error,
  &__label {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__label {
    color: var(--gray-color-500);
    display: flex;
    align-items: center;
    column-gap: 5px;

    svg {
      color: var(--gray-color-400);
    }
  }

  &__error {
    color: var(--danger-color-600);
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__error-icon,
  &__info-icon {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
  }

  &__info {
    color: var(--primary-color-700);
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__disabled {
    span {
      color: var(--gray-color-400) !important;
    }
  }

  &_not-outlined {
    position: relative;
    grid-template-areas:
    "field"
    "error";

    .locl-label-error-provider__label {
      position: absolute;
      left: 5px;
      top: -8px;
      z-index: 2;

      font-size: 12px;
      line-height: 18px;

      background-color: var(--white-color-500);

      padding: 0 4px;
      pointer-events: none;
    }
  }

  :global {
    .locl-select-label {
      margin: 0;
    }
  }
}
