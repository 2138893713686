.locl-auth-login-form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  font-family: var(--main-font-family);

  &__group {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    &-row {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__forgot-password {
    margin-left: auto;

    color: var(--danger-color-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:hover,
    &:focus,
    &:active {
      color: var(--danger-color-500);
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  }

  &__action {
    color: var(--secondary-color-500);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__action-link {
    color: var(--primary-color-500);
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-color-500);
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 740px) {
  .locl-auth-login-form {
    &__group {
      grid-gap: 24px;
  
      &-row {
        grid-template-columns: 1fr;
      }
    }
  }
}
