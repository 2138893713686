.locl-sortable-list {
  padding: 0;
  list-style: none;
}

.locl-sortable-list-item {
  list-style: none;

  &__dragging {
    opacity: 0.4;
  }
}

.locl-sortable-list-drag-block {
  display: flex;
  grid-gap: 4px;
  min-width: 1px;
  width: 100%;

  &__content {
    width: 100%;
    min-width: 1px;
  }

  .locl-sortable-list-drag-handle {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    align-self: center;
  }
}

.locl-sortable-list-drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  color: var(--dark-color-300);

  &__icon {
    width: 100%;
    height: 100%;
  }
}
