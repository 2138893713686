.locl-status-label {
  & * {
    box-sizing: border-box;
  }

  width: max-content;
  min-width: 166px;
  height: 32px;
  padding: 4px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;

  &__icon {
    width: 26px;
    height: 26px;

    background-color: var(--white-color-500);
    border: 1px solid var(--white-color-500);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  svg.locl-status-label__inner-icon {
    width: 20px;
    height: 20px;

    &_check {
      width: 24px !important;
      height: 24px !important;
    }

    &_interest {
      width: 20px;
      height: 20px;
    }

    &_check-availability {
      width: 24px;
      height: 24px;
    }
  }

  &__text {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &_full-width {
    width: 100%;
  }

  &_status-green {
    background-color: var(--primary-color-100);

    .locl-status-label__icon {
      color: var(--primary-color-500);
    }
  }

  &_status-unavailable {
    background-color: var(--danger-color-300);

    .locl-status-label__icon {
      color: var(--danger-color-500);
    }
  }

  &_status-purple {
    background-color: var(--purple-color-100);

    .locl-status-label__icon {
      color: var(--purple-color-500);
    }
  }

  &_status-option {
    background-color: var(--yellow-color-200);

    .locl-status-label__icon {
      color: var(--yellow-color-500);
    }
  }

  &_status-gray,
  &_status-cancel {
    background-color: var(--gray-color-300);

    .locl-status-label__icon {
      color: var(--gray-color-500);
    }
  }

  &_status-gray-check {
    background-color: var(--gray-color-300);

    .locl-status-label__icon {
      background-color: var(--white-color-500);
      color: var(--gray-color-500);
    }
  }

  &_status-gray {
    svg.locl-status-label__inner-icon {
      width: 14px;
      height: 14px;
    }

    .locl-status-label__icon {
      background-color: var(--gray-color-500);
      border: 3px solid var(--white-color-500);
      color: var(--white-color-500);
    }
  }

  &_status-expired {
    background-color: var(--secondary-color-300);

    .locl-status-label__icon {
      color: var(--secondary-color-500);
    }
  }

  &_small {
    min-width: 100px;
    height: 24px;

    .locl-status-label {
      &__text {
        font-size: 12px;
        line-height: 16px;
      }

      &__icon {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
      }
    }
  }
}
