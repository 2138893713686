.locl-location-space-info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 210px;
  height: 140px;
  flex: 0 0 210px;

  grid-gap: 8px;

  padding: 15px;

  background: rgb(186, 163, 139);
  color: rgb(255, 255, 255);

  font-size: 14px;
  line-height: 18px;

  &__label {
    text-align: center;
  }

  &__icon {
    width: 32px;
    height: 32px;
  }
}
