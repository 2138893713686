.locl-info-tooltip {
  :global {
    .ant-tooltip-inner {
      border-radius: 4px;
      background-color: var(--black-color-500);
      padding: 8px;

      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--white-color-500);
    }

    .ant-tooltip-arrow-content::before {
      background-color: var(--black-color-500);
    }
  }
}

.locl-info-tooltip-icon {
  columns: inherit;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
  }

  &__primary svg {
    color: var(--primary-color-500) !important;
  }

  &__size-medium {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }

  &__size-small {
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
  }
}
