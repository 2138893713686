.locl-landlord-upload-brands {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background: transparent !important;

  &__title {
    font-family: var(--secondary-font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--dark-color-500);
  }

  &__subtitle {
    font-family: var(--secondary-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--dark-color-500);

    margin: 4px 0 0 0;
  }

  &__upload-block {
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0 16px 32px;
    gap: 20px;
    border: 1px dashed var(--dark-color-100);
    border-radius: 4px;

    &_drag-over {
      border: 1px dashed #13B5AC;
      background: var(--primary-color-100);

      .locl-landlord-upload-brands__upload-block__hint {
        filter: opacity(30%);
      }
    }

    &_drag-error {
      cursor: not-allowed !important;
      border-color: red;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      color: #CBCBCB;

    }

    &__hint {
      &__title {
        font-family: var(--secondary-font-family);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: var(--dark-color-500);
      }

      &__subtitle {
        font-family: var(--secondary-font-family);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: rgba(0, 0, 0, 0.45);
        margin: 4px 0 0 0;
      }
    }

    &__button {
      font-family: var(--secondary-font-family);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-width: 124px;
      height: 36px;
      border-radius: 4px;
      margin: 0 24px 0 74px;
      border: 1px solid var(--dark-color-100) !important;
      background: rgb(255, 255, 255) !important;
      color: rgb(19, 181, 172) !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;

      &:disabled {
        background: var(--gray-color-300);
        opacity: 0.5;
      }

      &__icon { font-size: 16px; }
    }

    &__input { display: none; }
  }

  &__tag {
    width: 100%;
    height: 54px;
    padding: 0 16px 0 16px;
    margin: 24px 0 0 0;

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    background: #92285519;
    border-radius: 4px;

    font-family: var(--secondary-font-family);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #922855;

    &__icon { font-size: 18px; }
    &__close {
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__table-header {
    display: flex;
    flex-direction: row;
    margin: 16px 0 0 0;

    font-family: var(--secondary-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #545454;
    text-transform: uppercase;

    &__col-name {
      flex: 1;
      margin: 0 -40px 0 40px;
    }

    &__col-brand { flex: 1; }
  }

  &__file-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 4px 0 4px 0;
    gap: 10px;

    background: #FFFFFF;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.03), 0 4px 2px rgba(0, 0, 0, 0.03), 0 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &_active {
      cursor: grabbing !important;
      background: var(--primary-color-100) !important;
    }

    &__name {
      flex: 1;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
      overflow: hidden;
  
      height: 62px;
      width: 100%;
  
      padding: 0 0 0 12px;

      &__icon {
        margin: 0;
        width: 20px;
        font-size: 20px;
        color: #D9D9D9;
        display: flex;
        align-items: center;

        cursor: pointer;
      }

      &__text {
        font-family: var(--secondary-font-family);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: var(--primary-color-700);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__brand {
      flex: 1;
      align-items: center;
      margin: 0 19px 0 0;
  
      display: flex;
      gap: 12px;

      &__input {
        flex: 1;
        height: 35px;
  
        padding: 5px 12px 5px 12px;
  
        font-family: var(--secondary-font-family);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }

      &__delete {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 26px;
        font-size: 26px;
        color: #7D7D7D;
      }
    }
  }

  &__error {
    padding: 5px 0px;
    line-height: 15px;
    min-height: 0px;
    font-size: 12px;
    color: var(--danger-color-800);
  }
}

.locl-landlord-upload-brands-loading {
  .locl-landlord-upload-brands {
    &__file-row {
      &__name {
        &__icon {
          :global {
            .ant-skeleton-button {
              width: 13px;
              min-width: 13px;
              height: 22px;
            }
          }
        }

        &__text {
          width: 100%;
          
          :global {
            .ant-skeleton {
              .ant-skeleton-title {
                height: 24px;
              }
            }
          }
        }
      }

      &__brand {
        &__input {
          display: flex;
          align-items: center;
      
          :global {
            .ant-skeleton-input {
              width: 100%;
              min-width: auto;
              height: 35px;
            }
          }
        }

        &__delete {
          display: flex;
          align-items: center;

          :global {
            .ant-skeleton-button {
              width: 26px;
              min-width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
  }
}
