.locl-location-space-images-block {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 62.5%;
  overflow: hidden;

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.locl-location-space-images {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  
  &__many-images {
    grid-template-columns: 1fr 0.5fr;

    .locl-location-space-images-block {
      &:first-child {
        grid-row: 1 / 3;
      }
    }
  }

  &__actions {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 10;

    display: flex;
    grid-gap: 8px;

    &__button-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-location-space-images {
    grid-template-columns: 1fr;

    &__many-images {
      grid-template-columns: 1fr;
    }

    &__actions {
      right: 8px;
      left: 8px;
      bottom: 8px;
    }
    
    &__all-images-button {
      width: calc(100% - 16px);
    }

    &__virtual-tour-button,
    &__floor-plan-button {
      display: none;
    }

    .locl-location-space-images-block {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
