.locl-auth-login-form-footer {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--secondary-color-300);
  background: var(--white-color-500);
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 12px;
  font-family: var(--main-font-family);

  &__text {
    color: var(--secondary-color-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
  }

  &__link {
    width: 133px;
    height: 36px;
    flex: 0 0 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--secondary-color-300);
    background-color: var(--white-color-500);
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
    padding: 6px 14px;
    color: var(--secondary-color-500);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    
    &:hover,
    &:active,
    &:focus {
      color: var(--secondary-color-500);
    }
  }
}
