.locl-auth-form-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  font-family: var(--main-font-family);

  &__header {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    text-align: center;
  }

  &__body { display: block; }

  &__title {
    color: var(--secondary-color-500);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__subtitle {
    color: #4B5563;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 8px;
  }

  &__message {
    :global {
      .ant-tag.locl-tag {
        padding: 7px 17px;
  
        .locl-tag__text,
        .ant-tag-close-icon {
          color: var(--dark-color-500) !important;
        }
      }
    }
  }
}
