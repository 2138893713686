.locl-getting-started-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;

  font-family: var(--main-font-family);

  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;

  &:last-of-type {
    &:after { display: none; }
  }

  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--secondary-color-400);

    top: 9px;
    left: 50%;
    z-index: 1;

    transition: all .2s ease;
  }

  &_active {
    .locl-getting-started-step__inner {
      border-color: var(--amber-color-600);
      background: var(--amber-color-600);
    }
    .locl-getting-started-step__title { color: var(--primary-color-500); }
  }

  &_pass {
    .locl-getting-started-step__check-icon {
      opacity: 1;
    }

    &:after { background-color: var(--primary-color-500); }

    .locl-getting-started-step__inner {
      background-color: var(--primary-color-500);
      border-color: transparent;
    }

    .locl-getting-started-step {
      &__title,
      &__label,
      &__action-button { color: var(--gray-color-400); }
    }
  }

  &_hide-info {
    .locl-getting-started-step__title { color: var(--gray-color-400); }
    .locl-getting-started-step__label { visibility: hidden; }
    .locl-getting-started-step__action-button { visibility: hidden; }
  }

  &__inner {
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: var(--white-color-500);
    border: 1px solid var(--secondary-color-400);

    position: relative;
    z-index: 2;
  }

  &__check-icon {
    width: 14px;
    height: 14px;
    flex: 0 0 14px;

    opacity: 0;
    color: var(--white-color-500);

    transition: .2s all ease;
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: var(--dark-color-500);
    text-align: center;
  }

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--gray-color-500);
    text-align: center;
  }

  &__action-button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-color-500);

    text-decoration: underline;

    margin-top: auto;

    &:hover,
    &:focus {
      color: var(--primary-color-500);
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: 20px auto;
    justify-content: flex-start;
    gap: 6px;
    column-gap: 8px;

    &__action-button,
    &__label {
      grid-column: 2/-1;
      text-align: left;
    }

    &__title { text-align: left; }

    &:after {
      width: 2px;
      height: 100%;
      left: 9px;
    }
  }
}
