:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button__link: locl-button__link;
}

:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal: locl-modal;
  locl-modal__header: locl-modal__header;
}

:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-label: locl-modal__header-label;
}

.locl-lockout-modal {
  transition: all .2s;
  max-width: 500px;
  max-height: 80vh;
  height: max-content;
  
  &_hidden { opacity: 0; }

  &.locl-modal {
    :global {
      .ant-modal-title > * {
        font-family: var(--main-font-family);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: var(--secondary-color-500);
      }
  
      .ant-modal-header { padding: 16px 16px 16px; }
  
      .ant-modal-body { padding: 0 16px 16px !important; }
  
      @media screen and (max-width: 470px) { max-width: 95vw; }
    }
  }

  .locl-modal__header-label {
    width: 100%;
  }

  &__description {
    font-family: var(--main-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--gray-color-500);

    margin-bottom: 16px !important;
  }
  
  &__footer {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    margin-top: 24px;

    &__button-sign-up {
      border-color: var(--danger-color-500) !important;
    }

    &__button-more-info {
      color: var(--secondary-color-500);
    }

    &__buttons-search {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
    }
  }

  &__footer-logo {
    margin-top: 24px;
    text-align: center;

    &__tag {
      width: 100%;
      height: auto;
    }
  }

  .locl-modal__header {
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .locl-lockout-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    max-height: var(--window-height, 100vh);
    height: auto;
    margin: 0 !important;
    padding: 0;
    top: 0;

    :global {
      .ant-modal-content {
        height: var(--window-height, 100vh);
        border-radius: 0 !important;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
      } 
    }

    &__footer {
      position: sticky;
      bottom: 0;
      background: var(--white-color-500);
      margin-top: auto;

      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 20px;
        display: block;

        background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
      }
    }
  }
}
