.locl-auth-registration-form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  font-family: var(--main-font-family);
  
  &__header {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    text-align: center;
    padding-bottom: 8px;
  }

  &__title {
    color: var(--secondary-color-500);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__subtitle {
    color: var(--gray-color-500);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }


  &__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  &__password {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    &__label {
      color: var(--gray-color-400);
      font-size: 12px;
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;

    :global {
      .locl-label-error-provider__show-error {
        .ant-checkbox-wrapper .ant-checkbox-inner {
          border-color: var(--danger-color-500);
        }
      }
    }
  }

  &__action {
    color: var(--secondary-color-500);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__action-link {
    color: var(--primary-color-500);
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-color-500);
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__continue-button {
    width: 100%;

    &:active,
    &:hover {
      color: var(--white-color-500) !important;
    }
  }

  :global {
    .locl-wording-auth-sign-up-link {
      color: var(--primary-color-500);
      text-decoration: underline;
  
      &:hover,
      &:active,
      &:focus {
        color: var(--primary-color-500);
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .locl-auth-registration-form {
    &__group{
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }

    &__checkboxes {
      grid-gap: 16px;
    }
  }
}
