:import("@/assets/styles/components/Auth/auth-form-wrapper.module.scss") {
  locl-auth-form-wrapper: locl-auth-form-wrapper;
  locl-auth-form-wrapper__subtitle: locl-auth-form-wrapper__subtitle;
  locl-auth-form-wrapper__body: locl-auth-form-wrapper__body;
}

:import("@/assets/styles/components/Auth/auth-login-form.module.scss") {
  locl-auth-login-form: locl-auth-login-form;
  locl-auth-login-form__fields: locl-auth-login-form__fields;
}

.locl-login-modal {
  width: 620px !important;

  :global {
    .ant-modal-content {
      min-height: 300px;
    }

    .ant-modal-title {
      font-family: var(--main-font-family);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: var(--secondary-color-500);
    }
  }

  .locl-auth-form-wrapper__subtitle { text-align: left; }
}

@media screen and (max-width: 767px) {
  .locl-login-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    max-height: var(--window-height, 100vh);
    height: auto;
    margin: 0 !important;
    padding: 0;
    top: 0;

    :global {
      .ant-modal-content {
        min-height: 1px;
        height: var(--window-height, 100vh);
        border-radius: 0 !important;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
      } 
    }

    .locl-auth-form-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;

      &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }

    .locl-auth-login-form {
      flex: 1;

      &__fields { margin-bottom: auto; }
    }
  }
}
