.locl-location-space-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  grid-gap: 4px;

  &__label {
    line-height: 20px;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    font-weight: 500;
  }

  &__description {
    font-family: var(--secondary-font-family);
    font-weight: 400;
    line-height: 18px;
    color: #252525;
  }
}
