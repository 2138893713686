.locl-ops-base-layout {
  display: flex;
  gap: 8px;
  min-height: 100vh;

  background-color: var(--gray-color-300);

  &__main {
    flex: 0 0 1062px;
    margin: 24px auto;

    width: 100%;
    overflow-x: hidden;
  }
}
