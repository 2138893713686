.locl-location-space-carousel {
  height: 500px;
  border-radius: 4px;

  &__navigation {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    background-color: var(--white-color-500);
    color: var(--black-color-500);
    opacity: 0.5;
    z-index: 1;

    cursor: pointer;
    transition: all .2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: .7;
    }

    &_right {
      right: 4px;
    }

    &_left {
      left: 4px;
    }
  }

  &__slide {
    height: auto;
    width: 100% !important;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &__video {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__video-wrapper {
      position: relative;

      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 650px) {
      justify-content: flex-start;
    }
  }

  &__slide-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }


  &_full-width {
    width: 100%;
  }

  &:global(.swiper) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .swiper-pagination {
      width: max-content;
      left: unset;
    }
  
    .swiper-pagination-bullet {
      transition: all .2s;
      background: var(--white-color-500);
      opacity: 0.5;
  
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }

    .ant-image-preview-img {
      max-height: 80vh;
      max-width: 80vw;
      object-fit: cover;
    }
  }

  &__square-navigation {
    &:hover {
      .locl-location-space-carousel__navigation {
        transition: 0.2ms;
        opacity: 1;
      }

      :global {
        .swiper-pagination {
          transition: 0.2ms;
          opacity: 1;
        }
      }
    }

    :global {
      .swiper-pagination {
        opacity: 0;
      }

      .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background: var(--dark-color-800);
      }
    }

    .locl-location-space-carousel__navigation {
      opacity: 0;
      background: rgba(255, 255, 255, 0.20);
      box-shadow: 0px 20px 25px 0px rgba(31, 41, 55, 0.10), 0px 10px 10px 0px rgba(31, 41, 55, 0.04);
      color: var(--white-color-500);
      border-radius: 4px;
      width: 24px;
      height: 24px;
    }
  }
}

.locl-video-modal {
  width: 720px !important;
  height: max-content;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__video-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  &__video-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }

  :global {
    .ant-modal-close-x {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  
    .ant-modal-body {
      padding: 0;
      padding-top: 48px;
    }
  
    .ant-modal-content {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  
    .anticon { display: none; }
    .ant-modal-confirm-content {
      margin: 0 !important;
      width: 720px;
      height: 480px !important;
    }
    .ant-modal-confirm-btns { display: none; }
  }
}
