.locl-custom-weekend-period {
  grid-column: 1/-1;

  display: grid;
  grid-template-columns: repeat(7, 1fr);

  :global {
    .ant-checkbox-wrapper {
      width: 100%;

      &::after { display: none; }
    }

    .ant-checkbox {
      box-sizing: initial;
      padding: 12px 38px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;

      & + span {
        border: 1px solid #D9D9D9;
        width: 100%;
        height: 40px;
        border-radius: 2px;
  
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        font-family: var(--secondary-font-family);
        font-weight: 500;
        line-height: 20px;
        color: var(--dark-color-500);
      }
    }  

    .ant-checkbox-checked::after { border: none; }
  }

  .locl-checkbox-group .ant-checkbox-inner::after,
  .locl-checkbox .ant-checkbox-inner::after {
    height: 8px;
    width: 8px;
  }

  .locl-checkbox-group .ant-checkbox, .locl-checkbox .ant-checkbox {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }
  
  &__checkbox {
    border-radius: 2px;
    background-color: var(--white-color-500);
    flex-direction: column-reverse;
  }

  .locl-input-old__error {
    grid-column: 1/-1;
    margin-top: 4px;
  }
}
