
.locl-restricted-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);

  padding: 16px;

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--dark-color-800);
    text-align: center;
  }

  &__button {
    &.locl-button {
      width: 100%;
      max-width: 196px;
    }
  }

  &__info {
    display: block;
    padding: 8px;
    text-align: center;
    background: var(--danger-color-50);
    border-radius: 6px;

    width: 100%;
    max-width: 506px;
  }

  &__info-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__info-icon {
    display: inline-flex;
    height: 14px;
    margin: 0 4px;
    vertical-align: middle;
    padding-left: 4px;
  }
}
