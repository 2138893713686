:import("@/assets/styles/shared/button.module.scss") {
  locl-button__link: locl-button__link;
}

.locl-app-footer {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--secondary-color-300);
  padding: 12px 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1250px;
  }

  &__left {
    display: flex;
    align-items: center;
    grid-gap: 48px;
  }

  &__copyright {
    font-family: var(--main-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__nav {
    display: flex;
    align-items: center;
    grid-gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nav-link {
    font-family: var(--main-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
    
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        color: var(--primary-color-500);
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    grid-gap: 18px 24px;
  }

  &__email {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    font-family: var(--main-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    } 
    
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        color: var(--primary-color-500);
      }
    }
  }

  &__email-icon-ll {
    font-size: 20px;
    color: rgb(100, 103, 119);
  }

  &__button-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &__support {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        color: var(--primary-color-500);
      }
    }
  }
}

.locl-app-footer-ll {
  .locl-app-footer {
    &__nav-link {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1300px) {
  .locl-app-footer  {
    &__inner {
      width: 100%;
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .locl-app-footer  {
    padding: 20px 0;

    &__inner {
      flex-direction: column-reverse;
      grid-gap: 24px;
    }

    &__left {
      width: 100%;
      justify-content: space-between;
    }

    &__right {
      grid-gap: 28px;
      width: 100%;
    }

    &__support {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 867px) {
  .locl-app-footer  {
    padding: 28px 0 20px;

    &__inner {
      padding: 0 16px;
      grid-gap: 24px;
      width: 100%;
      max-width: 500px;
    }

    &__left {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      grid-gap: 24px;
    }

    &__nav {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px 32px;
    }

    &__right {
      width: 100%;
      flex-direction: column;
      grid-gap: 16px;

      :global {
        .locl-button {
          width: 100%;
          &__link { width: 100%; }
        }
      }
    }

    &__right-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
