.locl-search-space-drawer {
  :global {
    .ant-drawer-header {
      padding: 24px;
      border-bottom: none;
    }
  
    .ant-drawer-body {
      padding: 0 16px 24px;
      min-width: 660px;
  
      @media screen and (max-width: 720px) {
        min-width: 320px;
      }
    }
  
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
  
      .ant-drawer-close {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .ant-drawer-title { font-family: var(--main-font-family); }
    }
  
    .ant-drawer-content-wrapper {
      width: 100% !important;
      max-width: 800px;
  
      @media screen and (max-width: 490px) {
        width: 100% !important;
      }
    }
  }

  &__card-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__skeleton {
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;

    &:global(.ant-skeleton) {
      width: 100%;
      height: 170px;

      :global {
        .ant-skeleton-input {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  // Because drawer max-width = 690px
  @media screen and (max-width: 800px) {
    :global {
      .ant-drawer-header { padding: 24px 16px; }
    }
  }

  @media screen and (max-width: 520px) {
    :global {
      .ant-drawer-content-wrapper {
        max-width: 100%; 
      }
  
      .ant-drawer-body {
        width: 100%;
        overflow-x: hidden;
      }
    }
  }
}
