.locl-switch {
  max-width: 20px;

  &-label {
    width: max-content;
  }

  &:global(.ant-switch-checked) {
    background: var(--primary-color-500);
  }
}
