.locl-notification {
  border: 1px solid var(--secondary-color-300);
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 4px;

  padding: 16px !important;
  width: max-content !important;
  max-width: 500px !important;

  @media screen and (max-width: 590px) {
    max-width: calc(100vw - 48px) !important;
  }

  &__close-icon {
    width: 24px;
    height: 24px;
  }

  &_success {
    :global {
      .ant-notification-notice-icon {
        background-color: var(--primary-color-100);
        color: var(--primary-color-700);
      }
    }
  }

  &_warning {
    :global {
      .ant-notification-notice-icon {
        background-color: var(--amber-color-500);
        color: var(--yellow-color-100);
      }
    }
  }

  &_error {
    :global {
      .ant-notification-notice-icon {
        background-color: var(--rose-color-100);
        color: var(--rose-color-500);
      }
    }
  }

  &_info {
    :global {
      .ant-notification-notice-icon {
        background-color: var(--indigo-color-100);
        color: var(--indigo-color-500);
      }
    }
  }

  :global {
    .ant-notification-notice-with-icon {
      position: relative;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-notification-notice-message {
        margin-bottom: 4px !important;
        padding-right: 24px !important;
      }

      .ant-notification-notice-description,
      .ant-notification-notice-message {
        margin-left: 48px !important;
      }
    }

    .ant-notification-notice-icon {
      top: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 0;

      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .ant-notification-notice-description,
    .ant-notification-notice-message {
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: var(--secondary-color-500) !important;

      a {
        color: var(--primary-color-500) !important;
        text-decoration: underline;
      }
    }

    .ant-notification-notice-description {
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color-500);

      max-width: 320px;
      margin-right: 30px;
    }

    .ant-notification-notice-btn {
      float: none;

      display: block;
      margin-left: 48px;
    }

    .ant-notification-notice-close {
      inset-inline-end: 16px !important;
      top: 16px !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  &_painted {
    &.locl-notification {
      border: none;
      padding: 8px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 8px;
      min-width: 320px;

      &_info {
        background: var(--blue-color-100);
       
        :global {
          .ant-notification-notice-icon {
            background-color: transparent;
            color: var(--blue-color-500);
          }

          .ant-notification-notice-description {
            color: var(--blue-color-500) !important;
          }
        }
      }

      &_success {
        background: var(--primary-color-100);

        :global {
          .ant-notification-notice-icon {
            background-color: transparent;
            color: --primary-color-500;
          }
        }
      }

      &_error {
        background: var(--danger-color-300);

        :global {
          .ant-notification-notice-icon {
            background-color: transparent;
            color: --danger-color-500;
          }
        }
      }

      :global {
        .ant-notification-notice-with-icon {
          min-height: 20px;
        }

        .ant-notification-notice-icon {
          width: 20px;
          height: 20px;
          padding: 0;
        }

        .ant-notification-notice-message {
          display: none;
        }

        .ant-notification-notice-description {
          margin-left: 28px !important;
          margin-right: 0;
        }

        .ant-notification-notice-close {
          position: static;
          width: 20px !important;
          height: 20px !important;
          flex: 0 0 20px;
          color: var(--secondary-color-500);
        }
      }
    }
  }
}
