.locl-go-back-button {
  width: max-content;
  border: none;
  padding: 0;
  margin: 0;
  
  background-color: transparent;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  color: #000000;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;

  &__icon {
    display: block;

    width: 24px;
    height: 24px;
    flex: 0 0 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
