:import("@/assets/styles/components/card-carousel.module.scss") {
  locl-card-carousel: locl-card-carousel;
}

:import("@/assets/styles/components/space-card.module.scss") {
  locl-space-card__title: locl-space-card__title;
}

:import("@/assets/styles/containers/location-space-carousel.module.scss") {
  locl-location-space-carousel__slide: locl-location-space-carousel__slide;
}

:import("@/assets/styles/containers/location-space-info.module.scss") {
  locl-location-space-info__address-item-label: locl-location-space-info__address-item-label;
  locl-location-space-info__description-label: locl-location-space-info__description-label;
}

:import("@/assets/styles/layouts/single-page-layout.module.scss") {
  locl-single-page-layout: locl-single-page-layout;
}

:import("@/assets/styles/components/back-to-search-results.module.scss") {
  locl-back-to-search-results__link: locl-back-to-search-results__link;
}

:import("@/assets/styles/pages/location-single-page/location-single-page-header.module.scss") {
  locl-location-single-page-header: locl-location-single-page-header;
  locl-location-single-page-header__title: locl-location-single-page-header__title;
}

:import("@/assets/styles/containers/location-space-details-card.module.scss") {
  locl-location-space-details-card__header: locl-location-space-details-card__header;
  locl-location-space-details-card__header__title: locl-location-space-details-card__header__title;
}

:import("@/assets/styles/components/location-space-images.module.scss") {
  locl-location-space-images: locl-location-space-images;
}


:import("@/assets/styles/components/collapse.module.scss") {
  locl-collapse: locl-collapse;
}

.locl-location-single-page {
  font-family: var(--main-font-family);
  padding: 16px 0 48px 0;

  &_restricted {
    .locl-location-single-page-header__title,
    .locl-location-space-info__address-item-label,
    .locl-location-space-info__description-label,
    .locl-space-card__title {
      filter: blur(7px);
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .locl-card-carousel { pointer-events: none; }
    
    .locl-card-carousel__slide-image {
      filter: blur(20px);
      pointer-events: none;
    }
    .locl-location-space-carousel__slide {
      filter: blur(20px);
      pointer-events: none;
    }
  }
  
  &_restricted-icon {
    display: inline-flex;
    height: 14px;
    vertical-align: middle;
  }

  &_small-padding {
    &.locl-single-page-layout {
      padding-top: 32px;

      @media screen and (max-width: 992px) {
        padding-top: 0;
        margin-top: 16px;
      }
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 476px 1fr;
    grid-gap: 16px;

    &__left {
      position: sticky;
      top: 92px;

      display: flex;
      flex-direction: column;
      grid-gap: 24px;

      height: auto;
      max-height: calc(100vh - 120px);
      overflow: auto;
    }

    &__right {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
      min-width: 1px;
    }
  }

  &__carousel {
    width: 100%;
    height: 364px;
  }

  .locl-location-space-details-card {
    &__header {
      border-bottom: 1px solid var(--secondary-color-300);
      width: 100%;
    }

    &__header__title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      padding-bottom: 8px;
    }
  }

  .locl-back-to-search-results__link {
    margin-bottom: -8px;
  }
}

.locl-location-faq {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  padding: 24px;
  background: var(--white-color-500);
  border-radius: 4px;

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500);
    padding-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid var(--secondary-color-300);
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    background: var(--secondary-color-200);
  }

  &__answer {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  .locl-collapse {
    .locl-location-faq__block {
      :global {
        .ant-collapse-header {
          .ant-collapse-header-text {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--secondary-color-500);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .locl-location-single-page {
    &__inner {
      grid-template-columns: 1fr;

      &__left {
        position: static;
        top: auto;
        max-height: none;
        overflow: visible;
      }
    }

    .locl-location-space-details-card {
      &__header {
        border-bottom: none;
        width: 100%;
      }
  
      &__header__title {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-location-single-page {
    padding-left: 16px;
    padding-right: 16px;

    .locl-location-single-page-header {
      padding: 0;
    }

    .locl-location-space-images {
      margin: 0 -16px;
    }
  }
}
