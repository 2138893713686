.locl-landlord-upload-other-photos {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  width: 100%;

  &__with-images {
    .locl-landlord-upload-other-photos__upload {
      width: 158px;
      flex: 0 0 158px;
    }
  }

  &__upload {
    min-width: 158px;
  }
}
