.locl-space-images-upload {
  display: grid;
  grid-template-columns: 465px 210px;

  grid-gap: 24px;

  &__controls {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 4px;
  }

  &__label {
    line-height: 20px;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    font-weight: 500;

    color: var(--dark-color-500);
  }

  &__label-description {
    font-family: var(--secondary-font-family);
    line-height: 18px;
    color: #252525;
    font-weight: 400;
    font-size: 14px;
  }

  &__upload-control {
    margin-top: 4px;
    width: 100%;

    display: flex;
    flex-direction: column;

    grid-gap: 12px;
  }

  &__upload-btn {
    background-color: transparent !important;
    color: #13b5ac !important;
    border: 2px solid #13b5ac !important;
    border-radius: 5px;
    width: 140px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  &__files-list {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__error {
    padding: 5px 0px;
    line-height: 15px;
    min-height: 0px;
    font-size: 12px;
    color: #d05050;
  }
}

.locl-space-images-upload-loading {
  &__upload {
    :global {
      .ant-skeleton-button {
        width: 100%;
        max-width: 140px;
        height: 35px;
      }
    }
  }
}
