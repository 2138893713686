:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-icon: locl-modal__header-icon;
}

.locl-completed-step-modal {
  width: 640px !important;

  :global {
    .ant-modal-header { padding: 32px 32px 16px; }
    .ant-modal-body {
      padding: 0 32px;
      max-height: 60vh;
      overflow: auto;
    }
    .ant-modal-footer {
      margin: 0;
      padding: 24px 32px 32px;
      
      .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin: 0 !important;
      }
    }
  }
  
  &__title {
    display: flex;
    align-items: center;
    grid-gap: 12px;

    &__icon {
      width: 32px;
      height: 32px;
      color: var(--primary-color-500);
    }

    &__text {
      color: var(--secondary-color-500);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  }

  &__description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: var(--gray-color-500);

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;

    &__button {
      &_close {
        text-decoration: underline;

        background: none;
        outline: none;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--gray-color-500);
      }
    }    
  }

  &__video { width: 100%; }

  &__link {
    &_gray {
      color: var(--gray-color-500);
      text-decoration: underline;

      &:hover {
        color: var(--gray-color-500);
      }
    }

    &_survey {
      display: flex;
      align-items: center;
      grid-gap: 4px;
      color: var(--primary-color-500);
      text-decoration: underline;

      &:hover {
        color: var(--primary-color-500);
      }
    }

    &_icon {
      width: 20px;
      height: 20px;
    }
  }

  &__intercom-icon {
    width: 24px;
    height: 24px;
    fill: var(--danger-color-500);
  }

  .locl-modal__header-icon { display: none; }
}

@media screen and (max-width: 767px) {
  .locl-completed-step-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0;
    top: 0;
    left: 0;

    :global {
      .ant-modal-content {
        height: var(--window-height, 100vh);
        display: flex;
        flex-direction: column;
        border-radius: 0 !important;
      }

      .ant-modal-body {
        flex: 1;
        overflow: auto;
        background: var(--white-color-500);
        min-height: 1px;
        max-height: none;
        padding: 0 16px;
      }

      .ant-modal-header { padding: 16px 16px 12px; }
      .ant-modal-footer { padding: 12px 16px 16px;
        
        .locl-button {
          width: 100%;
        }
      }
    }

    .locl-modal__header-icon { display: flex; }
  }
}
