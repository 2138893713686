
:import("@/assets/styles/components/Auth/auth-registration-form.module.scss") {
  locl-auth-registration-form__footer: locl-auth-registration-form__footer;
}

.locl-sign-up-modal {
  width: 640px !important;

  :global {
    .ant-modal-content {
      min-height: max-content;
    }

    .ant-modal-body {
      padding-top: 0;
      max-height: 75vh;
      overflow: auto;
    }

    .ant-modal-title {
      font-family: var(--main-font-family);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: var(--secondary-color-500);
    }
  }
}

@media screen and (max-width: 767px) {
  .locl-sign-up-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    max-height: var(--window-height, 100vh);
    height: auto;
    margin: 0 !important;
    padding: 0;
    top: 0;

    :global {
      .ant-modal-content {
        min-height: 1px;
        height: var(--window-height, 100vh);
        border-radius: 0 !important;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        max-height: none;
        padding-bottom: 0 !important;
      } 
    }

    .locl-auth-registration-form__footer {
      position: sticky;
      bottom: 0;
      padding-bottom: 24px;
      background: var(--white-color-500);

      margin-top: auto;

      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 20px;
        display: block;

        background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
      }
    }
  }
}
