:import("@/assets/styles/components/info-tooltip.module.scss") {
  locl-info-tooltip-icon: locl-info-tooltip-icon;
}
:import("@/assets/styles/components/label-error-provider.module.scss") {
  locl-label-error-provider__label: locl-label-error-provider__label;
}
:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

:import("@/assets/styles/components/search-filters.module.scss") {
  locl-search-filters__content: locl-search-filters__content;
}

.locl-more-filters {
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;

    grid-gap: 24px;

    :global {
      .ant-collapse { gap: 24px !important; }
      .ant-collapse-header-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        svg { color: var(--gray-color-400); }
      }
    }
  }

  &__main-filter {
    .locl-search-filters__content {
      border: none;
      padding: 0;
      box-shadow: none;
    }
  }

  &__panel {
    :global {
      .ant-collapse-header {
        background-color: transparent !important;
        flex-direction: row-reverse;
        gap: 2px !important;
        padding: 0 !important;
        margin-bottom: 12px;
      }

      .ant-collapse-expand-icon {
        border: none !important;
        box-shadow: none !important;
        color: var(--gray-color-400) !important;
      }

      .ant-collapse-content-box { padding: 0 !important; }
    }

    &__title {
      display: flex;
      align-items: center;
      grid-gap: 8px;
    }

    &__badge-new {
      padding: 2px 4px;
      min-height: 20px;
      background: var(--primary-color-500);
      color: var(--white-color-500);
      border-radius: 4px;

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }

  &__title {
    color: var(--secondary-color-500);
    font-family: var(--main-font-family);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px !important;
    font-weight: 500;

    display: flex;
    align-items: center;
    grid-gap: 4px;

    .locl-info-tooltip-icon { color: var(--gray-color-400) }
  }

  &__locations-types {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    &__block {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
    }

    &__block-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: var(--secondary-color-500);      
    }

    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 2px;
      min-width: 1px;

      padding: 6px 4px;
      border: 1px solid var(--gray-color-600);
      border-radius: 4px;

      cursor: pointer;

      &:hover {
        background: var(--primary-color-100);
        transition: 0.2s;
      }

      &__tooltip {
        :global {
          .ant-tooltip-inner {
            background: var(--black-color-500);
          }
    
          .ant-tooltip-arrow {
            &::before {
              background: var(--black-color-500);
            }
          }
        }
      }

      &__text {
        font-family: var(--main-font-family);
        font-style: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: var(--gray-color-500);

        margin: 0;

        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &__img {
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
      }

      svg {
        color: var(--gray-color-600);
        min-height: 24px;
      }

      &__selected {
        background: var(--primary-color-100);

        .locl-more-filters__locations-types__card__text {
          color: var(--primary-color-800);
        }
      }

      &__disabled {
        background: var(--gray-color-300);
        cursor: not-allowed;

        &:hover {
          background: var(--gray-color-300);
        }
      }
    }
  }

  &__checkboxes {
    &__wrap {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 16px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, auto);
      }

      @media screen and (max-width: 650px) {
        grid-template-columns: 100%;
      }
    }
  }

  &__input-wrap {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 22px;
    }

    .locl-label-error-provider__label {
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--secondary-color-500);
    }
  }

  &__target-audience {
    display: flex;
    flex-direction: column;
  }

  &__gender {
    &__radio { margin-bottom: 8px; }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding: 12px 24px 24px 24px;
    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    background: var(--white-color-500);

    &__text {
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: var(--primary-color-700);
    }

    @media screen and (max-width: 490px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 12px;

      .locl-button { width: 100%; }
    }
  }

  &__tooltip {
    max-width: 186px;

    :global {
      .ant-tooltip-inner { text-align: center; }
    }
  }
}
