.locl-getting-started-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  &__header {
    font-family: var(--main-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--dark-color-500);

    &_hint {
      color: var(--gray-color-500);
      font-weight: 400;
      margin-left: 4px;
    }

    &_percent {
      font-weight: 500;
      color: var(--primary-color-500);
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;

    &__header {
      font-size: 14px;

      &_title { display: none; }
      &_hint {
        margin-left: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;

        display: block;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
