.locl-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: max-content;
  grid-gap: 16px;

  & > li { margin: 0 !important; }

  :global {
    .locl-pagination {
      &__arrow.ant-pagination-item-link {
        border-radius: 100%;
        background: var(--white-color-500);
        border: 1px solid var(--secondary-color-300);
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    
        color: var(--secondary-color-500);
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        svg {
          width: 12px;
          height: 12px;
        }
    
        &:hover {
          color: var(--secondary-color-500);
          background: var(--secondary-color-200);
          border: 1px solid var(--secondary-color-300);
        }
      }
    }

    .ant-pagination-next {
      &:hover {
        .ant-pagination-item-link {
          color: var(--secondary-color-500);
          background: var(--secondary-color-200);
          border: 1px solid var(--secondary-color-300);
        }
      }
    }
  
    .ant-pagination-disabled {
      .locl-pagination__arrow.ant-pagination-item-link {
        color: var(--gray-color-400);
  
        &:hover { background: var(--white-color-500); }
      }
    }
  
    .ant-pagination-item {
      border-radius: 100%;
      border: none;
  
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      min-width: 24px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media screen and (max-width: 620px) {
        flex: 0 0 32px;
        height: 32px !important;
        width: 32px;
      }
  
      .locl-pagination__page-item {
        padding: 0;
  
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-color-500);
  
        &:hover { color: var(--primary-color-500); }
      }
  
      .ant-pagination-item-link-icon { color: var(--primary-color-500); }
    }
  
    .ant-pagination-item-active {
      color: var(--white-color-500);
      background-color: var(--primary-color-500);
  
      .locl-pagination__page-item {
        color: var(--white-color-500) !important;
      }
    }
  
    .ant-pagination-item-link-icon { color: var(--primary-color-500) !important; }
  
    .ant-pagination-simple-pager {
      font-family: var(--main-font-family);
  
      input {
        font-family: var(--main-font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-color-500);
  
        background: var(--white-color-500);
        border: 1px solid var(--secondary-color-300);
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        border-radius: 4px;
  
        transition: all .2s;
    
        &:not([disabled]):hover {
          border: 1px solid var(--gray-color-400);
          box-shadow: 0px 0px 0px 3px rgb(229, 231, 235);
        }
      
        &:focus,
        &:active {
          border: 1px solid var(--primary-color-500) !important;
          box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
        }
      }
    }
  }

  &:global(.ant-pagination-simple) {
    :global {
      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          background: var(--white-color-500);
          border: 1px solid var(--secondary-color-300);
        }
      }
    }
  }

  @media screen and (max-width: 620px) {
    gap: 8px;

    &:global(.ant-pagination-simple) {
      :global {
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
        }

        .ant-pagination-simple-pager {
          height: 32px;
      
          display: flex;
          align-items: center;
        }
      }
    }

    &:global(.ant-pagination-mini) {
      :global {
        .ant-pagination-prev,
        .ant-pagination-next {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
        }
      }
    }
  }
}
