.locl-floor-plan-view {
  width: 100%;
  
  &__container {
    position: relative;
    margin: 30px 0;
  }

  &__mark {
    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    padding: 0 3px 3px 0;
    width: 40px;
    height: 40px;
    background: #f23e44;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -2.2em 0 0 -1.3em;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    transition: left 0.5s cubic-bezier(0.42, -0.3, 0.78, 1.25), top 0.5s cubic-bezier(0.42, -0.3, 0.78, 1.25);
  
    &::after {
      content: " ";
      width: 1em;
      height: 1em;
      margin: 17px 0 0 10px;
      background: #ffffff;
      position: absolute;
      border-radius: 50%;
    }
  }

  img { width: 100%; }
}

.locl-floor-plan-view-loader {
  width: 100%;

  :global {
    .ant-skeleton-button {
      width: 100%;
      min-width: auto;
      height: 300px !important;
    }
  }
}
