.locl-collapse {
  border: none;
  border-radius: 0;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  grid-gap: 12px;

  :global {
    .ant-collapse-header {
      padding: 4px;
      border-radius: 4px 4px 0 0;
  
      display: flex;
      align-items: center !important;
      justify-content: flex-start;
      grid-gap: 8px;
  
      background-color: var(--secondary-color-200);
  
      .ant-collapse-header-text {
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-color-500);
      }
    }

    .ant-collapse-expand-icon {
      order: 1;
      cursor: pointer;
  
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px !important;
      flex: 0 0 28px;
      padding: 3px !important;
      border-radius: 4px;
      background: var(--white-color-500);
      border: 1px solid var(--secondary-color-300);
      box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
    }
  
    .ant-collapse-item {
      width: 100%;
  
      overflow: hidden;
  
      border-radius: 4px;
      border: none;
  
      .ant-collapse-content-box { padding: 10px 12px; }
    }
  
    .ant-collapse-content {
      border-top: none;
      background-color: transparent;
    }
  
    .ant-collapse-ghost {
      .ant-collapse-header { background-color: transparent; }
    }
  }

  &__expand-icon {
    padding: 0 !important;
    margin: 0 !important;

    width: 100%;
    height: 100%;
    
    color: var(--gray-color-400);

    transition: all .2s;

    &_active {
      transform: rotate(90deg);
    }
  } 

  &_size-medium {
    &:global(.ant-collapse) {
      :global {
        .ant-collapse-header {
          padding: 10px 8px;
    
          .ant-collapse-header-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  &_size-large {
    &:global(.ant-collapse) {
      :global {
        .ant-collapse-header {
          padding: 12px 8px;
    
          .ant-collapse-header-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }

  &_size-xlarge {
    &:global(.ant-collapse) {
      :global {
        .ant-collapse-header {
          padding: 14px 8px;
    
          .ant-collapse-header-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
