.locl-button {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: var(--white-color-500);
  background: var(--primary-color-500);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  border: none;
  padding: 8px 16px;

  height: 36px;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;

  outline: unset !important;

  &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
    color: var(--white-color-500);
    background: var(--primary-color-500);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  }

  &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
    color: var(--white-color-500);
    background: var(--primary-color-400);
  }

  &_icon {
    padding: 8px;
  }

  &_danger {
    background: var(--danger-color-500);

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
      background: var(--danger-color-500);
    }

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
      border-color: var(--danger-color-400);
      background: var(--danger-color-400);
    }
  }

  &[ant-click-animating-without-extra-node="true"]::after {
    box-shadow: 0 0 0 0 var(--white-color-500);
  }

  &_type-secondary {
    color: var(--secondary-color-500);
    background: var(--white-color-500);
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
      color: var(--secondary-color-500);
      background: var(--white-color-500);
      border: 1px solid var(--secondary-color-300);
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    }

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
      color: var(--secondary-color-500);
      border-color: var(--secondary-color-300);
      background: var(--secondary-color-200);
      border: 1px solid var(--secondary-color-400);
    }

    &.locl-button_danger {
      color: var(--danger-color-500);
      background: var(--white-color-500);
      border: 1px solid var(--danger-color-500);

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
        color: var(--danger-color-500);
        background: var(--white-color-500);
        border: 1px solid var(--secondary-color-300);
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      }

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
        color: var(--danger-color-500);
        border-color: var(--danger-color-500);
        background: var(--danger-color-300);
      }
    }

    &.locl-button_success {
      color: var(--primary-color-500);
      border: 1px solid var(--primary-color-500);

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
        color: var(--primary-color-500);
        border: 1px solid var(--primary-color-500);
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      }

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
        color: var(--primary-color-500);
        border-color: var(--primary-color-100);
        background: var(--primary-color-100) !important;
      }
    }

    &[disabled] {
      border-color: var(--dark-color-200);
      background: var(--white-color-500);
      color: var(--gray-color-400);

      &:focus {
        border-color: var(--dark-color-200);
        background: var(--white-color-500);
      }

      &:hover {
        border-color: var(--dark-color-200);
        background: var(--white-color-500);
        color: var(--gray-color-400);
      }
    }
  }

  &_type-tertiary {
    color: var(--primary-color-750);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
      color: var(--primary-color-750);
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
      color: var(--primary-color-750);
      border-color: var(--primary-color-100);
      background: var(--primary-color-100);
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    }

    &.locl-button_danger:not([disabled]) {
      color: var(--danger-color-500);

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):focus) &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):active) {
        color: var(--danger-color-500);
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
        border-color: var(--danger-color-300);
        background: var(--danger-color-300);
        color: var(--danger-color-500);
      }
    }
  }

  &_size-small {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px;

    height: 28px;

    &.locl-button_icon {
      padding: 4px;
    }
  }

  &_size-large {
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    padding: 10px 24px;

    height: 46px;
  }

  &_full-width {
    width: 100%;
  }

  &_success {
    color: var(--primary-color-500);
    border: 1px solid var(--primary-color-500);

    &:focus,
    &:hover {
      color: var(--primary-color-500) !important;
      border: 1px solid var(--primary-color-500) !important;
    }
  }

  &_ghost {
    background-color: transparent !important;
  }

  &__link {
    width: max-content;

    &_full-width {
      width: 100%;
    }
  }

  &:global(.ant-btn > .ant-btn-loading-icon .anticon) {
    padding: 0;
  }

  :global {
    .ant-btn-loading-icon {
      display: flex;
    }
    .ant-btn-icon:not(:last-child) {
      margin-right: 0 !important;
    }
  }
}
