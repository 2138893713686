:import("@/assets/styles/shared/tag.module.scss") {
  locl-tag: locl-tag;
}

.locl-search-criteria-tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;


  &__box {
    &.locl-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 4px;
      padding: 4px 6px;
      border: none;
    }
  }
}
