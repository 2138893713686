:import("@/assets/styles/containers/location-space-carousel.module.scss") {
  locl-location-space-carousel__navigation: locl-location-space-carousel__navigation;
}
:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}


.locl-location-card {
  &__thumbnail {
    position: absolute;
    height: 250px;
    width: 274px;
    object-fit: contain;
    left: 50%;
    margin-left: -137px;

    z-index: 1;
    opacity: 1;
  }

  &__image {
    width: 100%;
    max-height: 250px;

    &-wrapper {
      width: 100%;
      margin-bottom: 8px;
    }

    &:hover {
      .locl-location-space-carousel__navigation {
        transition: 0.2ms;
        opacity: 1;
      }

      :global {
        .swiper-pagination {
          transition: 0.2ms;
          opacity: 1;
        }
      }
    }

    :global {
      .swiper-pagination {
        opacity: 0;
      }

      .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
      }
    }

    .locl-location-space-carousel__navigation {
      opacity: 0;
      background: rgba(255, 255, 255, 0.20);
      box-shadow: 0px 20px 25px 0px rgba(31, 41, 55, 0.10), 0px 10px 10px 0px rgba(31, 41, 55, 0.04);
      color: var(--white-color-500);
      border-radius: 4px;
      width: 24px;
      height: 24px;
    }
  }

  &__info {
    font-family: var(--main-font-family);

    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-bottom: 8px;
  }

  &__city {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 2px 4px;
    background: rgba(17, 24, 39, 0.50);
    border-radius: 4px;
    z-index: 10;

    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: var(--white-color-500);
  }

  &__name {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    color: var(--secondary-color-800);
    display: inline-block;
    
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    &__restricted {
      filter: blur(5px);
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &__show-spaces-button {
    background: var(--primary-color-100);
    margin-top: auto;

    &:hover {
      background: transparent;
    }
  }

  &__show-spaces-button-link {
    margin-top: auto;
    text-decoration: underline !important;
    transition: all 0.3s, font-weight 0s;

    &:hover {
      background: transparent;
      font-weight: 700;
    }

    &.locl-button {
      padding: 4px;
      height: auto;
      font-weight: 500;
      background: var(--gray-color-300);
      border-color: var(--gray-color-300);
    }
  }

  &__buttons {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;

    margin-top: auto;
    padding-top: 16px;

    .locl-button { width: 100%; }
  }

  &__demographic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    justify-content: space-between;
    column-gap: 8px;

    &__item {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:last-child {
        text-align: right;
        justify-content: flex-end;
      }

      svg {
        flex: 0 0 14px;
        width: 14px;
        margin-right: 4px;
        color: var(--primary-color-500);
      }

      &__value {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: var(--secondary-color-500);
      }

      &__label {
        display: inline-block;
        margin-left: 4px;
      }
    }
  }

  &__wrap {
    background: var(--white-color-500);
    padding: 8px 8px 14px 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    position: relative;
    border: 1px solid transparent;

    width: 100%;
    min-width: 1px;

    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--gray-color-300);
        box-shadow: 0px 20px 25px rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04);
      }
    }
  }

  &__price {
    color: var(--secondary-color-500);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__collections {
    display: flex;
    grid-gap: 4px;

    &__box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      border-radius: 4px;
      background: var(--primary-color-100);
      color: var(--primary-color-900);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__badge-new {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: var(--danger-color-650);
    background: var(--danger-color-200);
    z-index: 20;
  }
}

.locl-location-card-preloader {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 8px 8px 14px 8px;
  border-radius: 4px;

  background-color: var(--white-color-500);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

  height: max-content;

  &__image {
    &:global(.ant-skeleton-element) { height: 130px; }
  }
  &__title {
    overflow: hidden;

    &:global(.ant-skeleton-element) {
      height: 55px;
      width: 200px;
    }
  }
  &__location-info {
    &:global(.ant-skeleton-element) {
      height: 58px;
      margin: 10px 0;
    }
  }
  &__drawer-button {
    overflow: hidden;

    &:global(.ant-skeleton-element) {
      width: 120px;
      height: 20px;
    }
  }

  :global {
    .ant-skeleton .ant-skeleton-input { height: 100%; }
  }
}
