:import("@/assets/styles/components/space-booking-terms.module.scss") {
  locl-space-booking-terms: locl-space-booking-terms;
}

:import("@/assets/styles/shared/status-label.module.scss") {
  locl-status-label: locl-status-label;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-create-abstract-event-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  &__column {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__controls {
    display: flex;
    grid-gap: 8px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    h4 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__single-row {
    grid-column: 1/-1;

    &__label {
      font-family: var(--main-font-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px !important;
      color: var(--gray-color-500);
      display: flex;
      align-items: center;
      column-gap: 5px;

      svg {
        color: var(--gray-color-400);
      }
    }
  }

  &__description {
    border: 1px solid #13B5AC;
    border-radius: 6px;

    &_top {
      padding: 12px;
      display: flex;
      align-items: center;
      background: var(--primary-color-100);
      border-radius: 6px;

      &_img {
        margin-right: 12px;
        border-radius: 4px;
      }

      &_info {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
      
        grid-gap: 12px;
      }

      &_title {
        display: flex;
        flex-direction: column;
        grid-gap: 4px;
      }

      &_location {
        font-weight: 400;
        font-size: 16px;
        display: inline-block;
        font-family: var(--main-font-family);
        color: var(--secondary-color-500);
      }

      &_space {
        font-family: var(--main-font-family);
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        color: var(--secondary-color-500);
      }

      &_dimension {
        margin: 0;
        padding: 0;

        font-size: 12px;
        color: var(--gray-color-500);
        font-family: var(--main-font-family);
      }
    }

    &_top-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 4px;

      &__main {
        width: 100%;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 8px;
        align-items: center;
      }

      .locl-create-abstract-event-form__description_top_img { 
        width: 100px;
        height: 70px;
        margin: 0;
      }
    }

    &__bottom {
      padding: 10px 12px;
      display: flex;
      justify-content: space-between; 
      align-items: center;
      column-gap: 20px;
      font-family: var(--main-font-family);

      &__period {
        display: flex;
        flex-direction: column;
        grid-gap: 6px;

        &__range {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          white-space: nowrap;
        }

        &__total {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          color: var(--gray-color-500);
        }
      }

      &__status {
        margin-bottom: 0;
      }

      &__avg {
        margin-bottom: 0;
        white-space: nowrap;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;

        &__total {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: var(--gray-color-500);
        }

        &__value {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;

          white-space: normal;
          color: var(--primary-color-500);
        }

        &__tax {
          white-space: nowrap;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    padding-top: 12px;
    border-top: 1px solid var(--gray-color-300);
  }
}

@media screen and (max-width: 640px) {
  .locl-create-abstract-event-form {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;

    // Need to fix long file name in uploader
    &__single-row { max-width: calc(100vw - 32px); }

    &__controls {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .locl-button { width: 100%; }
    }

    &__description {
      &__bottom {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4px;
        padding: 8px 12px;

        &__period { padding: 10px 0; }

        &__status {
          width: 100%;
        
          .locl-status-label { width: 100%; }
        }

        &__avg {
          flex: none;
          text-align: left;
          align-items: flex-start;
        }
      }
    }

    &__wrapper { grid-gap: 16px; }

    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-top: 12px;
      
      .locl-button { width: 100%; }
    }
  }
}
