:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old-wrapper: locl-input-old-wrapper;
}

.locl-consecutive-booking-day-discounts {
  width: max-content;
  min-width: 700px;
  background-color: var(--white-color-500);

  &__header {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: var(--dark-color-500);

    padding: 24px 16px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 84px;
    align-items: center;
    grid-auto-rows: minmax(47px, 62px);
    border-bottom: 1px solid #DEE2E6;

    :global {
      .ant-input-number {
        border: none;
        width: calc(100% - 5px);
      }
    }

    .locl-input-old-wrapper {
      margin: 16px;
    }
  }

  &__row-control {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.85);

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &__header-cell {
    margin: 16px;
    color: #212529;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;

    &:not(:last-child) { border-right: 1px solid rgba(0, 0, 0, 0.03); }
  }

  &__add-new-price-btn {
    background-color: transparent !important;
    color: var(--danger-color-500) !important;
    border-radius: 4px;
    border: 1px solid var(--danger-color-500);

    margin: 4px 16px 24px auto;

    &:focus {
      background-color: transparent !important;
      color: var(--danger-color-500) !important;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
