.locl-getting-started-progress {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  &__inner {
    display: grid;
    width: 300px;
    grid-template-columns: 5% 15% 35% 35% 10%;
    grid-auto-rows: 12px;
    align-items: center;
    justify-content: flex-start;
    
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    &__block {
      background-color: var(--secondary-color-200);
      border: 1px solid var(--secondary-color-400);

      height: 100%;
      transition: all .2s ease;

      &_active {
        background-color: var(--amber-color-600);
        border-color: var(--amber-color-600);
      }
      &_pass {
        background-color: var(--primary-color-500);
        border-color: var(--primary-color-500);
      }
    }
  }

  &__hint {
    font-family: var(--main-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    color: var(--dark-color-500);
  }

  @media screen and (max-width: 992px) {
    &__hint { display: none; }

    &__inner {
      width: 264px;
      grid-template-columns: 5% 15% 35% 35% 10%;
    }
  }
}
